<template>
    <b-card class="mb-4"
            header-bg-variant="custom"
            no-body
        >
            <template #header>
                <h4 v-html="$t(tKey + 'header')" class="text-white" />
            </template>
            <b-card-body>
                <b-table
                    class="small destination-table"
                    sticky-header
                    small
                    striped                    
                    caption-top
                    :caption="$t(tKey + 'caption')"
                    :items="data"
                    :fields="fields"
                >
                    <template #cell(destination)="data">
                        <span :id="data.item.destination" class="ml-1" v-html="data.item.destination.length > 20 ? (data.item.destination.substr(0, 20) + '...') : data.item.destination" />
                        <b-tooltip v-if="data.item.destination.length > 20" :target="data.item.destination" triggers="hover">
                            {{ data.item.destination }}
                        </b-tooltip>
                    </template>
                    <template #cell(overnights)="data">
                        <p class="mb-1" v-html="$utils.formatToFixed(data.item.overnights)" />
                        <p class="mb-1 font-weight-bold" :class="$utils.isPositive(data.item.overnights, data.item.overnigthsBef) ? 'text-success' : 'text-danger'">
                            <font-awesome-icon :icon="$utils.isPositive(data.item.overnights, data.item.overnigthsBef) ? 'angle-up' : 'angle-down'" />
                            {{ $utils.formatToFixed(Math.abs($utils.computeDifference(data.item.overnights, data.item.overnigthsBef))) }}%
                        </p>                    
                    </template>
                </b-table>                    
            </b-card-body>
    </b-card>                    
</template>

<script>
export default {
    name: 'DestinationTable',
    props: {
        data: { type: Array, required: true },
    },
    data() {
        return {
            tKey: 'dashboard.destination.',
        }
    },
    computed: {
        fields() {
            return [
                {
                    key: 'destination',
                    label: this.$t(this.tKey + 'destination'),                    
                },
                {
                    key: 'overnights',
                    label: this.$t(this.tKey + 'overnights'),
                    sortable: true,
                },
                {
                    key: 'arrivals',
                    label: this.$t(this.tKey + 'arrivals'),
                    formatter: (value) => this.$utils.formatToFixed(value),
                    sortable: true,
                },
            ]
        },
    },
}
</script>

<style lang="scss">
.destination-table {
    max-height: 58vh !important;
    & * {
        vertical-align: middle !important;
    }
}
</style>